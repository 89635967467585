import { AxiosError } from 'axios';

export const formUrlEncode = (obj: { [key: string]: any }): string => {
  const keys = Object.keys(obj);
  return keys
    .reduce<string[]>((prev: string[], curr: string) => {
      prev.push(`${curr}=${obj[curr].toString()}`);
      return prev;
    }, [])
    .join('&');
};

export const delay = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export interface Base64File {
  name: string;
  body: string;
};

export const readFileAsBase64Async = (file: File): Promise<Base64File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.addEventListener('load', () => {
      resolve({
        name: file.name,
        body: reader.result as string
      });
    });

    reader.addEventListener('error', (error: any) => {
      reject({
        message: error.message,
        name: file.name
      });
    });
  });
};

export const removeBase64Prefix = (str: string): string => {
  const values = str.split(',');
  return values.length === 2 ? values[1] : str;
};

export interface mapCallback<T, TReturn> {
  (item: T, index?: number): TReturn;
}
export interface Iterable<T> {
  [index: number]: T;
  length: number;
}

export function map<T, TReturn>(iterable: Iterable<T>, callback: mapCallback<T, TReturn>): TReturn[] {
  let index = 0;
  const result: TReturn[] = [];

  while (index < iterable.length) {
    result[index] = callback(iterable[index]);
    index++;
  }

  return result;
}

export const isPdfExtension = (extension: string) => {
  const regEx = /\.pdf\s*$/gi;
  return regEx.test(extension);
};

export const isImageExtension = (name: string) => {
  const imageExtensions = ['gif', 'ico', 'jpeg', 'jpg', 'tga', 'tiff', 'tif', 'svg', 'png', 'webp'];
  const ext = getExtension(name);
  return imageExtensions.indexOf(ext) > -1;
};

export const extractName = (path: string) => path.replace(/^.*[\\\/]/, '');

export const getFileName = (name?:string)=>{
  if (!name) {
    return '';
  }
  return name.slice(0 , name.lastIndexOf('.'));
}

export const getExtension = (name?: string) => {
  if (!name) {
    return '';
  }

  return name.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2).trim();
};

export const setNumberZeroPrefix = (arg: number): string => {
  const prefix = arg.toString().length > 1 ? '' : '0';
  return `${prefix}${arg}`;
};

export const toGermanTimeString = (date: string): string => {
  const dateTime = new Date(date);
  const hours = setNumberZeroPrefix(dateTime.getHours());
  const minutes = setNumberZeroPrefix(dateTime.getMinutes());
  return `${hours}:${minutes}`;
};

export const toGermanDateString = (date: string): string => {
  const dateTime = new Date(date);
  const year = dateTime.getFullYear();
  const month = setNumberZeroPrefix(dateTime.getMonth() + 1);
  const day = setNumberZeroPrefix(dateTime.getDate());
  return `${day}.${month}.${year}`;
};

export const getErrorMessage = (error: any): string => {
  let message = error?.message;
  if (error?.isAxiosError) {
    const response = (error as AxiosError).response;
    if (response?.status === 403) {
      message = 'Verboten';
    } else {
      const axiosResponseMessage = JSON.parse(JSON.stringify(response?.data));
      message = axiosResponseMessage ? axiosResponseMessage : message;
    }
  }

  return message as string;
};

export const validateEmail = (email: string): boolean => {
  const re = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,8}|[0-9]{1,3})(\]?)$/;
  return re.test(String(email).toLowerCase());
};

export enum Statuses{
   Created = 0,
   Signed = 1,
   NotAvailable = 2
};

export enum DisplayStatuses{
   NotAvailable = 'Nicht digital verfügbar',
   RequireSignature = 'Unterschrift erforderlich',
   Signed = 'Unterschrift geleistet'
};

export enum CustomerEnvelopeStatus{
    NotSignedByCurrentUser = 0,
    ShouldBeSignByCurrentUser = 1,
    SignedByCurrentUser = 2,
    Completed = 3,
    Deleted = 4,
    Created = 5
};

export type CustomerEnvelopeStatusI =
CustomerEnvelopeStatus.Completed | CustomerEnvelopeStatus.Created |
CustomerEnvelopeStatus.Deleted | CustomerEnvelopeStatus.NotSignedByCurrentUser |
CustomerEnvelopeStatus.ShouldBeSignByCurrentUser | CustomerEnvelopeStatus.SignedByCurrentUser;
