import React, { FunctionComponent, ReactElement } from 'react';
import {
  CircularProgress,
  List,
  Typography
} from '@material-ui/core';
import authService from '../../services/auth-service';
import { getErrorMessage , CustomerEnvelopeStatus} from '../../unit/constants';
import sharepointService from '../../services/sharepoint-service';
import {CustomerEnvelopes} from '../../services/sales-order-service';
import { withSnackbar, SnackbarProps } from '../../hoc/withSnackbar';
import {DocumentsScope, UngroupedSharepointFile} from './order';
import {EnvelopeList , DocumentsBelonging} from './envelope-list';
import {SHAREPOINT_SERVICE_URI} from '../../unit/url';
import classes from './order.module.scss';

interface DocumentsProps extends SnackbarProps{
  loading: boolean;
  documents: DocumentsScope;
  onDelete(id: string): void;
  onReplace(envelopeId:string, type:DocumentsBelonging):void;
  linksList: CustomerEnvelopes[];
  orderFolder:string | undefined;
};

const Documents: FunctionComponent<DocumentsProps> = ({
  loading,
  documents,
  onDelete,
  showSnackbar,
  onReplace,
  linksList,
  orderFolder
}: DocumentsProps): ReactElement => {
  const EnvelopeListType = 'envelope-list';
  const StandaloneType= 'standalone';

  const handleDocumentOpen = async (id: string):Promise<void> => {
    try{
      const fileToken = await authService.issueFileToken(id);
      let url = `${SHAREPOINT_SERVICE_URI}/api/documents/file/${fileToken}`;
      window.location.assign(url);
    }catch(error:any){
      console.log(error);
      showSnackbar(getErrorMessage(error), 'top' , 'center');
    }
  };


  const handleDelete = async (id: string, deleteCallback:()=>void): Promise<void> => {
    try {
      await sharepointService.deleteAsync(id);
      onDelete(id);
    } catch (error:any) {
      deleteCallback();
      showSnackbar(getErrorMessage(error), 'top' , 'center');
    }
  };

  const isAnimationExist = (envelopeId:string | null):boolean=>{
    const target = linksList.find( item => item.envelopeId === envelopeId);
    const findStatus = target && (target.status === CustomerEnvelopeStatus.Deleted || target.status === CustomerEnvelopeStatus.Completed);
    return !!target && !!findStatus;
  }

  const renderContent = ():JSX.Element[] | JSX.Element=>{
     if(loading){
       return (
        <div className='text-center'>
           <CircularProgress color='primary' style={{ margin: 'auto' }} />
        </div>
       )
     }

     if(!loading && Object.keys(documents.grouped).length === 0 && documents.ungrouped.length === 0){
        return (
          <Typography className='text-center'>Keine Daten</Typography>
        )
     }

     const groupedEnvelopes = Object.keys(documents.grouped);
     const withEnvelope:JSX.Element[] = groupedEnvelopes.map( (envelopeId:string, index:number) => (
      < EnvelopeList
          showSnackbar={showSnackbar}
          key={`${envelopeId}`}
          type={EnvelopeListType}
          isAnimate={isAnimationExist(envelopeId)}
          positionInList={index}
          listLength={groupedEnvelopes.length}
          envelopeName={envelopeId}
          documents={documents.grouped[envelopeId]}
          linksList={linksList}
          onOpen={handleDocumentOpen}
          onDelete={handleDelete}
          animateReplaceHandler={onReplace}
          folderName={linksList.find( elem => elem.envelopeId === envelopeId)?.folderName ?? 'Kein Name gefunden'}
          orderFolderName={orderFolder}
       />
     ))

     const withoutEnvelope = documents.ungrouped.map( (doc:UngroupedSharepointFile, index:number)=> (
      < EnvelopeList
          key={`${doc.documentId}`}
          documents={[doc]}
          sequenceIndex={index + 1}
          sequenceLgth={documents.ungrouped.length}
          type={StandaloneType}
          envelopeName={doc.envelopeId}
          isAnimate={isAnimationExist(doc.envelopeId)}
          onOpen={handleDocumentOpen}
          onDelete={handleDelete}
          animateReplaceHandler={onReplace}
      />
     ))
     return [...withEnvelope, ...withoutEnvelope];
  }

  return (
    <>
     <List className={classes.list}>
       {renderContent()}
     </List>
    </>
  );
};

export default withSnackbar(Documents);
