import { AnyAction } from 'redux';
import { SET_ENVELOPES , CHANGE_ENVELOPE,
         DELETE_ENVELOPE, SET_ORDER_DOCUMENTS,
         DELETE_DOCUMENT_FROM_ORDER_LIST,
        UPLOAD_DOCUMENT_TO_ORDER_LIST, REARRANGE_DOCUMENTS} from '../unit/reducerTypes';
import {CustomerEnvelopes} from '../services/sales-order-service';
import { DocumentsScope , UngroupedSharepointFile} from '../components/order/order';

export interface CustomerEnvelopesState {
   envelopes:CustomerEnvelopes[];
   orderDocuments:DocumentsScope;
};

const INITIAL_STATE:CustomerEnvelopesState = {
    envelopes:[],
    orderDocuments:{
      grouped:{},
      ungrouped:[]
    }
};

const envelopesReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_ENVELOPES:
      return { ...state, envelopes:[...action.payload]};
    case CHANGE_ENVELOPE:
        return { ...state , envelopes:[...state.envelopes.map(envelope => {
          if(envelope.envelopeId === action.payload.envelopeId){
            return {
                ...envelope,
                ...action.payload.updatedEnvelope
            };
          }
          return envelope;
        })
        ]};
    case DELETE_ENVELOPE:
       return {...state , envelopes:[...state.envelopes.filter( envelope => envelope.envelopeId !== action.payload)]};
    case SET_ORDER_DOCUMENTS:
       return {...state, orderDocuments:{...state.orderDocuments, ...action.payload}};
    case DELETE_DOCUMENT_FROM_ORDER_LIST:
       return {...state, orderDocuments:{...state.orderDocuments , ungrouped:[...state.orderDocuments.ungrouped.filter( f => f.documentId !== action.payload)]}};
    case UPLOAD_DOCUMENT_TO_ORDER_LIST:
       return {...state , orderDocuments:{...state.orderDocuments , ungrouped:[
            {...action.payload.document, envelopeId:null} as UngroupedSharepointFile,
            ...state.orderDocuments.ungrouped
          ]}
       };
    case REARRANGE_DOCUMENTS:
      return {...state , orderDocuments:{...state.orderDocuments , ungrouped:[
          ...state.orderDocuments.grouped[action.payload.envelopeId].map(t => ({...t, envelopeId:action.payload.envelopeId})),
          ...state.orderDocuments.ungrouped
          ]
        }
      };
    default:
      return {...state};
  }
};

export default envelopesReducer;
