import axios, { AxiosResponse } from 'axios';
import { withBearer } from './with-bearer';
import { SHAREPOINT_SERVICE_URI } from '../unit/url';
export interface SharepointFile {
  documentId: string;
  externalReference: null | string;
  isVisible: boolean;
  isCustomersPortalVisible: boolean;
  isSigned: boolean;
  isEnvelopeCreated: boolean;
  isSignedWithKabema: boolean;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  comment: string | null;
  documentType: string;
  documentTypeId: string;
  extension: string;
  [key: string]: boolean | string | null;
};

export interface FileModel{
  id: string | null,
  folders: {recordId:string, entityName:string}[],
  document: {
    documentTypeId: string,
    comment: string,
    isVisible: boolean,
    isCustomersPortalVisible: boolean,
    isEnvelopeCreated: boolean,
    isSigned: boolean,
    isSignedWithKabema?: boolean,
    isLuegShared?: boolean,
    modifiedOn?: string,
    modifiedBy?: string,
    externalReference?: string,
  },
  file?: {
    body: string,
    name: string,
  },
  forceUpload?: boolean;
};

class SharepointService {

  public async fetchFiles(entityId: string, entityName:string): Promise<AxiosResponse<SharepointFile[]>> {
    return axios({
      method: 'GET',
      url: `${SHAREPOINT_SERVICE_URI}/api/documents/${entityName}/${entityId}`,
      headers: withBearer({})
    });
  }

  public async uploadFileAsync(file: FileModel, validation:boolean): Promise<{retainedFile:SharepointFile, statusCode:number}> {
      return await new Promise(async (resolve, reject)=>{
        const result = await axios.post<SharepointFile>(`${SHAREPOINT_SERVICE_URI}/api/documents/upload`, file, {
          headers: withBearer({}),
          validateStatus:()=> true
        });
        if(validation && [409, 200, 500, 422].indexOf(result.status) > -1){
          return resolve({
                  retainedFile:result.data,
                  statusCode:result.status
                });
        }
        if(!validation && result.status === 200){
          return resolve({
                  retainedFile:result.data,
                  statusCode:result.status
                });
        }

        return reject({
                retainedFile:result.data,
                statusCode:result.status
              });
      })
  }

  public async deleteAsync(id: string): Promise<AxiosResponse<void>> {
    return axios.delete(`${SHAREPOINT_SERVICE_URI}/api/documents/delete/${id}`, {
      headers:  withBearer({})
    });
  }

  public async getFileAsync(token: string): Promise<AxiosResponse<any>> {
    const result = await axios.get(`${SHAREPOINT_SERVICE_URI}/api/documents/file/${token}`, {
        responseType: 'blob',
        headers: {
          Authorization: withBearer({})
        }
      });
      return result;
  }

}

const sharepointService = new SharepointService();
export default sharepointService;
