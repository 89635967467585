import axios, { AxiosResponse , CancelToken} from 'axios';
import {CustomerEnvelopeStatusI} from '../unit/constants';
import { withBearer } from './with-bearer';

export interface Dse_AND_OrdersResponse<DSE, Orders>{
   dse: DSE;
   records: Orders[];
};

export interface DseResponse{
  documentId: null | string;
  dynamicsStatus: number;
  link: null | string;
  name: null | string;
  recordId: null | string;
  status: number;
  signedTimestamp:string | null;
};

export interface SalesOrder{
  id: string;
  name: string;
  type: string | null;
  manufacturer: string | null;
  entityName:string | null;
  folder: string;
  createdOn: string;
  aufbau: null | string;
  besteller: null | string;
  calculationListId: null | string;
  envelopeAvailable: boolean;
  lackierungId: null | string;
  lackierungName: null | string;
};

export interface CustomerEnvelopes{
  envelopeId:string;
  link:string | null;
  status:CustomerEnvelopeStatusI;
  customerId:string;
  entityName:string;
  recordId:string;
  folderName:string;
  documentsId:string[];
};
export interface SalesOrderResponse {
  record:SalesOrder;
  customerEnvelopes:CustomerEnvelopes[];
};

interface CurrentUserSchema{
  id: string;
};

class SalesOrderService {

  public async getAll(): Promise<AxiosResponse<Dse_AND_OrdersResponse<DseResponse , SalesOrder>>> {
    return axios({
      method: 'GET',
      url: '/api/records',
      headers: withBearer({})
    });
  }

  public async getUrl(id: string): Promise<AxiosResponse> {
    return axios( {
      method: 'POST',
      url: `/api/pdf/get-signing-url`,
      data: {
        documentId : id
      },
      headers: withBearer({})
     }
    );
  }

  public async dsePreRequest(recordId:string){
    return axios( {
      method: 'POST',
      url: `/api/records/dse/${recordId}`,
      headers: withBearer({})
     }
    );
  }

  public async getSigningLinks(documentIds: string[]): Promise<AxiosResponse> {
    return axios( {
      method: 'POST',
      url: `/api/pdf/get-signing-links`,
      data: {
        documentIds
      },
      headers: withBearer({})
    }
    );
  }

  public async sendSignedDocument(documentsId: string[], envelopeId: string): Promise<AxiosResponse> {
    return axios( {
      method: 'POST',
      url: `/api/pdf/complete-signing`,
      data: {
        documentId : documentsId,
        envelopeJobId: envelopeId
      },
      headers: withBearer({})
    }
    );
  }

  public async getSalesOrder(id: string, entityName:string, cancelToken:CancelToken): Promise<AxiosResponse<SalesOrderResponse>> {
    return axios.get(`/api/records/${entityName}/${id}`, {
      cancelToken,
      headers: withBearer({})
    });
  }

  public async getCurrentUser(): Promise<AxiosResponse<CurrentUserSchema>> {
    return axios.get(`/api/users/get-current`, {
      headers: withBearer({})
    });
  }

  public async testSignalRMessage(orderId:string){
    return axios( {
      method: 'POST',
      url: `/api/pdf/send-message-to-clients`,
      data: {
        messages:[
          {
          "documentsId":['f326bcbd-f5c3-4c8b-a684-08dcbcf7db4e', '7beee219-9ee3-41fe-a685-08dcbcf7db4e'],
          "customerEnvelope":   {
          "envelopeId": "c1WLvM8vpO5GDpemO+Z7wGkLAlphiZakhJXlekH/VTxbhyyeQrcNmgIRvsKKQcjiTcA5bFUB1cQRYqZDpCjJJA==",
          "link": null,
          "status": 4,
          "customerId": "ab2caf4f-6b8a-ec11-93b0-6045bd8f30c1",
          "entityName": "lueg_kundenauftrag",
          "recordId": "98c283e3-c4d8-eb11-bacb-000d3abd0f25",
          "folderName": "D2022362L053"
        },
            "orderId": `${orderId}`,
            "envelopeAvailable": true
          }
        ]
      },
      headers: withBearer({})
    }
    );
  }


}

const salesOrderService = new SalesOrderService();
export default salesOrderService;
