import { AnyAction } from 'redux';
import { SET_ORDER, SET_ORDERS, SET_CURRENT_ORDER , UPDATE_ORDER} from '../unit/reducerTypes';
import {SalesOrder} from '../services/sales-order-service';

export type OrderState = SalesOrder;
export interface InitialOrdersState{
  order?: OrderState;
  [key: string]: OrderState | undefined;
};

const INITIAL_STATE: InitialOrdersState = {};

const orderReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_ORDER:
      return { ...state, [action.payload.key]: action.payload.value };
    case SET_ORDERS:
      return { ...state, ...action.payload };
    case SET_CURRENT_ORDER:
      return { ...state, order: action.payload };
    case UPDATE_ORDER:
      return {...state , [action.payload.key]: {...state[action.payload.key], envelopeAvailable:action.payload.envelopeAvailable }}
    default:
      return state;
  }
};

export default orderReducer;
