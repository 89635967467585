import { AnyAction } from 'redux';
import { CLOSE_MODAL, OPEN_MODAL } from '../unit/reducerTypes';

interface ModalState {
  type: string | null;
  params: object | null;
};

const INITIAL_STATE: ModalState = {
  type: null,
  params: null
};

const modalReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        type: action.payload.type,
        params: action.payload.params
      };
    case CLOSE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default modalReducer;
