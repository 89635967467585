import React, { FunctionComponent } from 'react';
import { List } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {Base64File} from '../../../unit/constants';
import {FileModel, SharepointFile} from '../../../services/sharepoint-service';
import Document from './document';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    }
  })
);

interface DocumentListProps{
  files: File[];
  onDeleteFile:(fileName:string)=>void;
  transformToUploadfileModel(file: Base64File):FileModel,
  uploadSuccessHandler(file:SharepointFile):void,
  eventName:string;
};

const DocumentList: FunctionComponent<DocumentListProps> = (props: DocumentListProps): JSX.Element => {
  const {files , ...rest} = props;
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {files.map((file: File, i: number) => (
        <Document
          key={`${file.name}_${i}`}
          file={file}
          {...rest}
        />
      ))}
    </List>
  );
};

export default DocumentList;
