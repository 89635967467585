import { AnyAction } from 'redux';
import { HEADER_HEIGHT , FOOTER_HEIGHT } from '../unit/reducerTypes';

interface Dimensions {
 header_height:number;
 footer_height:number;
};

const INITIAL_STATE: Dimensions = {
 header_height:0,
 footer_height:0
};

const dimensionsReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
     case HEADER_HEIGHT:
        return {...state, header_height: action.payload};
     case FOOTER_HEIGHT:
        return {...state , footer_height: action.payload};
     default:
      return state;
  }
};

export default dimensionsReducer;
