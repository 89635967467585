import { AnyAction } from 'redux';
import { SET_DSE , UPDATE_DSE} from '../unit/reducerTypes';

export interface DseState {
    documentId: null | string;
    dynamicsStatus: number | null;
    link: null | string;
    name: null | string;
    recordId: null | string;
    status: number | null;
    signedTimestamp:string | null;
};

const INITIAL_STATE: DseState = {
    documentId: null,
    dynamicsStatus: null,
    link: null,
    name: null,
    recordId: null,
    status: null,
    signedTimestamp:null,
};

const dseReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_DSE:
      return { ...state, ...action.payload };
    case UPDATE_DSE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default dseReducer;
