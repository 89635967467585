import React , { useState, FunctionComponent, ReactElement } from 'react';
import {
    ListItemAvatar,
    ListItemText,
    Typography,
    Card,
    CardContent,
  } from '@material-ui/core';
import { SharepointFile } from '../../services/sharepoint-service';
import ImageIcon from '@material-ui/icons/Image';
import { isPdfExtension } from '../../unit/constants';
import {DocumentsBelonging} from './envelope-list';
import { PdfIcon , OpenInNewIcon, DeleteIcon} from './icons';
import Loader from '../loader';
import {clsx} from 'clsx';
import classes from './order.module.scss';


interface DocumentItemProps{
  document: SharepointFile;
  itemIndex: number;
  docsLength: number;
  type: DocumentsBelonging;
  openHandler:(id:string)=>Promise<void>;
  deleteHandler:(id:string, deleteCallback:()=>void)=>Promise<void>;
  isSigned(isSigned:boolean):JSX.Element | null;
};

export const DocumentItem:FunctionComponent<DocumentItemProps> = (props):ReactElement=>{
  const {document , itemIndex, docsLength, type,  openHandler , deleteHandler, isSigned} = props;
  const [deletionInProcess , setDeleteAction] = useState<boolean>(false);

  const renderIcon = (extension: string): JSX.Element => {
    if (isPdfExtension(extension)) {
      return <PdfIcon />
    }
    return <ImageIcon fontSize='large'/>;
  }

  const deleteFinishedCallback = ()=>{
    setDeleteAction(false);
  }

  const handleDelete =(id:string):void=>{
    setDeleteAction(true);
    deleteHandler(id, deleteFinishedCallback);
  }

  return (
        <div className={
          clsx(classes.document,
          {
           [classes.separated]:type === 'envelope-list' && itemIndex !== docsLength,
           [classes.separated]:type === 'standalone' && itemIndex !== docsLength,
           [classes.envelope_doc]:type === 'envelope-list',
           [classes.standalone_doc]:type === 'standalone'
          }
         )}
        >
           <Card className={classes.card}>
              <CardContent className={classes.card_content_wrapper}>
                        <div className={
                          clsx(classes.document_attr,
                          {
                            [classes.envelope_doc]:type === 'envelope-list',
                            [classes.standalone_doc]: type === 'standalone'
                          }
                          )}
                        >
                          <ListItemAvatar>
                              <div className={classes.avatar} onClick={() => openHandler(document.documentId)}>
                               {renderIcon(document.extension)}
                              </div>
                          </ListItemAvatar>
                          <ListItemText className={classes.item_text}>
                              <Typography noWrap>{document.extension.slice(1)}</Typography>
                              <Typography noWrap>{document.documentType}</Typography>
                          </ListItemText>
                        </div>
              </CardContent>
            </Card>
            <div className={classes.actions}>
              { type === 'standalone' && isSigned(document.isSigned) }
              { type === 'standalone' && (!document.documentType || document.documentType === 'Hochgeladen vom Kunden') && (
                <div className={classes.icon_delete} onClick={() => handleDelete(document.documentId)}>
                  {deletionInProcess ? <Loader className={classes.delete_icon_loading}/> : <DeleteIcon />}
                </div>
              )}
              <div className={classes.icon_open} onClick={()=> openHandler(document.documentId)}>
                  <OpenInNewIcon />
              </div>
            </div>
        </div>
  )
}