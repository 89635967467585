import axios, { AxiosResponse } from 'axios';
import { formUrlEncode } from '../unit/constants';
import { IDENTITY_SERVICE_URI } from '../unit/url';
export interface TokenResponse {
  status:number;
  access_token :{
    access_token: string;
    expires_in: number;
    token_type: string;
    scope: string;
  };
};

export interface User{
  guid:string;
  name:string;
  phone:string;
};
interface UsersResponse{
  status:number;
  users?:User[];
};

interface ExpectedDataForSending{
  userName:string;
  verificationCode:string;
  userId?:string;
};

class AuthService {

  public async issueTokenAsync(userName: string, verificationCode: string, userId:string | null): Promise<AxiosResponse<TokenResponse>> {
    let data:ExpectedDataForSending = {
      userName,
      verificationCode
    }
    if(userId){
      data.userId = userId;
    }
    return axios({
      method: 'POST',
      url: '/api/authentication',
      data,
    });
  }

  public async verifyEmail(userName: string):Promise<AxiosResponse<UsersResponse>>{
    return axios({
      method: 'POST',
      url: `/api/authentication`,
      data: {
        userName
      }
    });
  }

  public async approveUserAuthentication(userName:string, userId:string):Promise<{status:number}>{
    const response = await axios({
      method: 'POST',
      url: `/api/authentication`,
      data: {
        userName,
        userId
      }
    });

    return response.data;
  }

  public async verifyPhoneNumberAsync(userName: string): Promise<AxiosResponse<void>> {
    return axios({
      method: 'POST',
      url: `${IDENTITY_SERVICE_URI}/api/verify-user-name`,
      data: formUrlEncode({
        userName: encodeURIComponent(userName)
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public async issueFileToken(id:string): Promise<string> {
    const token = sessionStorage.getItem('token');
    const { data } = await axios.get(`${IDENTITY_SERVICE_URI }/api/token?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    // TODO: handle error
    return data as string;
  }
  
}

const authService = new AuthService();
export default authService;
