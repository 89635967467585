import { AnyAction } from 'redux';
import { HubConnection } from '@microsoft/signalr';
import { SET_CONNECTED , SET_DISCONNECTED } from '../unit/reducerTypes';

export interface SignalRState {
    connected:false;
    SignalRHub:HubConnection | undefined;
};

const INITIAL_STATE: SignalRState = {
    connected:false,
    SignalRHub:undefined
};

const signal_R_Reducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_CONNECTED:
      return { ...state, connected:true,  SignalRHub:action.payload};
    case SET_DISCONNECTED:
      return { ...state , ...INITIAL_STATE}
    default:
      return state;
  }
};

export default signal_R_Reducer;
