import React , {useEffect , FunctionComponent , ReactElement} from 'react';
import { useDispatch } from 'react-redux';
import {
    ListItem,
    Button
  } from '@material-ui/core';
import { animated, useSpring, AnimationResult } from '@react-spring/web';
import {HourglassFullTwoTone} from '@material-ui/icons';
import { SharepointFile } from '../../services/sharepoint-service';
import {CustomerEnvelopes} from '../../services/sales-order-service';
import {CustomerEnvelopeStatusI , CustomerEnvelopeStatus} from '../../unit/constants';
import {deleteEnvelope} from '../../actions';
import {SigningIcon , SignedIcon} from './icons';
import {DocumentItem} from './document-item';
import classes from './order.module.scss';
import clsx from 'clsx';

interface EnvelopeListProps{
   documents: SharepointFile[];
   type:DocumentsBelonging;
   envelopeName:string | null;
   linksList?:CustomerEnvelopes[];
   sequenceIndex?:number;
   sequenceLgth?:number;
   orderFolderName?:string;
   folderName?:string;
   isAnimate?:boolean;
   positionInList?:number;
   listLength?:number;
   onOpen:(id:string)=>Promise<void>;
   onDelete:(id:string, deleteCallback:()=>void)=>Promise<void>;
   showSnackbar?(message:string):void;
   animateReplaceHandler(envelopeId:string, type:DocumentsBelonging):void;
};

export type DocumentsBelonging = 'standalone' | 'envelope-list';

export interface EnvelopeLinkStatus{
  status:CustomerEnvelopeStatusI;
  link: string | null;
};

interface EnvelopeStatusProps{
   envelopeStatus: EnvelopeLinkStatus | null;
};

  const EnvelopeStatus:FunctionComponent<EnvelopeStatusProps> = ({envelopeStatus}):JSX.Element=>{
    if(!envelopeStatus){
      return <></>;
    }

    const {status , link} = envelopeStatus;

    if(status === CustomerEnvelopeStatus.NotSignedByCurrentUser){
        return  <Button className={classes.default} variant='outlined'>
                  <HourglassFullTwoTone color='error'/><span>Warte bis du an der Reihe bist...</span>
                </Button>
    }

    else if(status === CustomerEnvelopeStatus.ShouldBeSignByCurrentUser && !!link){
       return <a
                target='_blank'
                href={link}
                rel='noopener noreferrer'
                className={classes.signing}
              >
                <SigningIcon />
                <span>Signieren</span>
              </a>
    }

    else if(status === CustomerEnvelopeStatus.SignedByCurrentUser){
      return  <Button className={classes.default} variant='outlined' >
                <HourglassFullTwoTone color='error'/><span>Unterschrift in der Schlussphase...</span>
              </Button>
    }

    else if(status === CustomerEnvelopeStatus.Completed){
       return <Button className={classes.default} variant='outlined'>
                <SignedIcon /><span>Unterschrift geleistet</span>
              </Button>
    }

    return <></>;

  }

    const fromAction = (type:DocumentsBelonging, standaloneIndex?:number, isAnimate?:boolean)=>{
      if(type === 'envelope-list'){
        return {
          from:{
            opacity: 1,
            height: '100%',
            y:0
          }
        }
      }

      return isAnimate ? {
        opacity:0,
        height: '0%',
        y:standaloneIndex! * 50
      } :
      { };
    }

    const toAction = (type:DocumentsBelonging , listLength?:number, positionInList?:number)=>{
      const isNotStandalone = type === 'envelope-list' && listLength && positionInList;
       if(isNotStandalone){
        return {
          to:{
           opacity:0,
           height:'0px',
           y:(listLength! - positionInList) * 100
          }
        }
       }

       return {
        to:{
          opacity:1,
          height: '100%',
          y:0
        }
      }
   }


export const EnvelopeList:FunctionComponent<EnvelopeListProps> =({
      documents , envelopeName, linksList, type,
      folderName, sequenceIndex, sequenceLgth, isAnimate,
      positionInList, listLength, onOpen, onDelete, animateReplaceHandler
    }):ReactElement=>{
      const dispatch = useDispatch();

      const [styles , api] = useSpring(()=>({
        ...fromAction(type , sequenceIndex, isAnimate),
        config:{
          duration: 500
        },
        onRest:(result: AnimationResult)=>{
          if(result.finished){
            if(type === 'envelope-list'){
              animateReplaceHandler(envelopeName! , type);
            }
            if(type === 'standalone'){
              dispatch(deleteEnvelope(envelopeName!));
            }
          }
        }
      }))

      useEffect(()=>{
        if(isAnimate){
          api.start({
           ...toAction(type , listLength, positionInList)
         });
        }
      } , [isAnimate]);

    const getEnvelopeStatus = (envelopeId: string):EnvelopeLinkStatus | null=>{
      const record = linksList?.find( item => item.envelopeId === envelopeId);

      if(!record){
        return null;
      }
      const {status , link} = record;
      return {
        status,
        link
      };
    }

    const haveAlreadySigned = (isSigned:boolean):JSX.Element | null =>{
      if(isSigned){
        return (
          <Button className={classes.signed} variant='outlined'>
            <SignedIcon /><span>Unterschrift geleistet</span>
          </Button>
        )
      }
      return null;
    }

    return(
      <animated.div style={styles}>
        <ListItem className={clsx(classes.list_item, {
          [classes.envelope_item]:type === 'envelope-list',
          [classes.standalone_item]:type === 'standalone',
          [classes.padding_top]:type === 'standalone' && sequenceIndex === 1,
          [classes.padding_bottom]:type === 'standalone' && sequenceIndex === sequenceLgth
         }
         )}
        >
            <section className={classes.lg_screen}>
              {type !== 'standalone' &&
              (<div className={classes.envelope_signing}>
                 <div className={classes.envelope_title}>Envelope: {folderName}</div>
                 <div className={classes.signing_status}>
                    <EnvelopeStatus envelopeStatus={getEnvelopeStatus(envelopeName!)}/>
                 </div>
              </div>)
              }
              <div className={classes.documents}>
                {
                  documents.map( (doc:SharepointFile, index:number) =>
                    <DocumentItem
                      key={doc.documentId}
                      itemIndex={type === 'standalone' ? sequenceIndex! : index + 1}
                      docsLength={type === 'standalone' ? sequenceLgth! : documents.length}
                      document={doc}
                      openHandler={onOpen}
                      deleteHandler={onDelete}
                      isSigned={haveAlreadySigned}
                      type={type}
                    />
                  )
                }
              </div>
            </section>
        </ListItem>
      </animated.div>
    )
}