import React, { FunctionComponent, ReactElement, useEffect, useState} from 'react';
import { Link, useRouteMatch , useLocation} from 'react-router-dom';
import { Container, Button, Modal, Box, Typography } from '@material-ui/core';
import Documents from './documents';
import DocumentList from './document-list';
import { useSelector, useDispatch } from 'react-redux';
import OrderInfo, { OrderSkeleton } from './order-info';
import DndArea from '../dnd-area';
import Header from '../header';
import Footer from '../footer';
import sharepointService, { FileModel, SharepointFile } from '../../services/sharepoint-service';
import salesOrderService, { SalesOrder, CustomerEnvelopes } from '../../services/sales-order-service';
import {CustomerEnvelopesState} from '../../reducers/customer-envelopes';
import { Base64File, removeBase64Prefix } from '../../unit/constants';
import { RootState } from '../../reducers';
import { SET_ORDER, SET_CURRENT_ORDER } from '../../unit/reducerTypes';
import {useError} from '../../hooks/useError';
import {getErrorMessage} from '../../helpers';
import {SignalRState} from '../../reducers/signalR';
import FileHubMethod from '../../signalR-events/signalR-events-instanse';
import {SalesOrderUpdateI , SalesOrderCreateI} from '../../signalR-events/signalR-events-interfaces';
import {setEnvelopes , changeEnvelopes, setOrderDocuments , deleteDocument, successUploadedDocument, rearrangeDocumentsInOrderList} from '../../actions';
import {DocumentsBelonging} from './envelope-list';
import store from '../../store';
import axios,  {AxiosError , CancelTokenSource} from 'axios';
import * as Labels from '../../unit/labels';
import classes from './order.module.scss';
import {ArrowBack} from '../login/login';

export type GroupedEnvelope =  {
   [key:string]:SharepointFile[];
};

export type UngroupedSharepointFile = SharepointFile & {envelopeId:string | null};

export type ReturnValue<G , D> = [G, D[]];
export interface DocumentsScope{
  grouped:GroupedEnvelope;
  ungrouped: UngroupedSharepointFile[];
};
interface LocationState{
  entityName:string;
};

const Order: FunctionComponent = (): JSX.Element | ReactElement=> {
  const match = useRouteMatch<{ id: string }>();
  const {state} = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // const [documents, setDocuments] = useState<DocumentsScope>({grouped:{}, ungrouped:[]});
  const orderDocuments = useSelector<RootState, DocumentsScope>(state => state.envelopes.orderDocuments);
  const order = useSelector<RootState, SalesOrder | undefined>(state => state.orders[match.params.id]);
  const {envelopes} = useSelector<RootState, CustomerEnvelopesState>(state => state.envelopes);
  const {connected , SignalRHub } = useSelector<RootState,SignalRState>( (state:RootState)=> state.signal_R);
  const navigateToError = useError();
  const dispatch = useDispatch();
  const entity_name = (state as LocationState).entityName;

  const catchError = (from:string, error:string)=>{
    if(loading){
      setLoading(false);
    }
    navigateToError({from, error});
  }

  const groupByEnvelope = (files:SharepointFile[], customerEnvelopes:CustomerEnvelopes[]):ReturnValue<GroupedEnvelope, UngroupedSharepointFile>=>{
    let noMatches:SharepointFile[] = !customerEnvelopes.length ? files : [];
    let envelopeMatched:GroupedEnvelope = {};
    if(customerEnvelopes.length){
      envelopeMatched = files.reduce((prev:GroupedEnvelope, current:SharepointFile):GroupedEnvelope=>{
        const documentId = current.documentId;
        let existInEnvelopes = false;

        for(let i =0; i < customerEnvelopes.length; i++){
          if(customerEnvelopes[i].documentsId.indexOf(documentId) > -1){
             const envelopeId = customerEnvelopes[i].envelopeId;
              if(prev[envelopeId]){
                  prev[envelopeId].push(current);
                  existInEnvelopes = true;
              }else{
                  prev[envelopeId] = [];
                  prev[envelopeId].push(current);
                  existInEnvelopes = true;
              }
          }
        }

        if(!existInEnvelopes){
          noMatches.push(current);
        }

        return prev;
      }, {});
    }
    return [
             envelopeMatched,
             noMatches.map( f => ({...f, envelopeId:null}))
           ];
  }

  const replacementHandler = (envelopeId:string, type:DocumentsBelonging)=>{
    if(type === 'envelope-list'){
      dispatch(rearrangeDocumentsInOrderList(envelopeId));
      // const ungrouped = documents.grouped[envelopeId].map( f => ({...f, envelopeId}));
      // setDocuments( prev => ({
      //    ...prev,
      //    ungrouped: [
      //     ...ungrouped,
      //     ...prev.ungrouped
      //   ]
      // }))
    }
  }

  useEffect(() => {
    let source:CancelTokenSource = axios.CancelToken.source();

    const fetchSalesOrderRelatedInfo = async ()=>{
      try{
        const response = await salesOrderService.getSalesOrder(match.params.id, entity_name, source.token);
        const {record , customerEnvelopes} = response.data;
        dispatch({ type: SET_CURRENT_ORDER, payload: record });
        dispatch({ type: SET_ORDER, payload: { key: record.id, value: record } });
        const files = await sharepointService.fetchFiles(record.id, record.entityName!);
        const [grouped , ungrouped]:ReturnValue<GroupedEnvelope, UngroupedSharepointFile> = groupByEnvelope(files.data , customerEnvelopes);
        dispatch(setOrderDocuments(grouped , ungrouped));
        // setDocuments((prev)=>({
        //   ...prev,
        //   grouped,
        //   ungrouped
        // }));
        if(customerEnvelopes.length){
          dispatch(setEnvelopes(customerEnvelopes));
        }
        if(loading){
          setLoading(false);
        }
      }catch(error){
        if(!axios.isCancel(error)){
           console.log(error);
           catchError(`/order/${match.params.id}`, getErrorMessage((error as AxiosError)));
        }
      }
    }
    fetchSalesOrderRelatedInfo();

    window.addEventListener('refresh-documents', fetchSalesOrderRelatedInfo);

    return () => {
      dispatch({ type: SET_CURRENT_ORDER, payload: undefined });
      window.removeEventListener('refresh-documents', fetchSalesOrderRelatedInfo);
      source.cancel();
    };
  }, [dispatch]);



  const SalesOrderEnvelopeCreateHandler = (message:SalesOrderCreateI)=>{
    const {recordId , entityName} = message;
    if(match.params.id === recordId && entityName === entity_name){
      window.dispatchEvent(new Event("refresh-documents"));
    }
  }

  const SalesOrderEnvelopeUpdateHandler = (message:SalesOrderUpdateI)=>{
    if(match.params.id === message.recordId && entity_name === message.entityName){
      const envelopes:CustomerEnvelopes[] = store.getState().envelopes.envelopes;
      const result = envelopes.filter( envelope => envelope.envelopeId === message.envelopeId);
      if(result.length) {
        const updatedEnvelope:CustomerEnvelopes = {...message};
        dispatch(changeEnvelopes(result[0].envelopeId, updatedEnvelope));
      }
    }
  }

  useEffect(()=>{
    if(connected && SignalRHub && !loading){
       SignalRHub.on(FileHubMethod.RecordEnvelopeCreate.toString(), SalesOrderEnvelopeCreateHandler);
       SignalRHub.on(FileHubMethod.RecordEnvelopeUpdate.toString(), SalesOrderEnvelopeUpdateHandler);

     return (): void => {
      SignalRHub.off(FileHubMethod.RecordEnvelopeCreate.toString());
      SignalRHub.off(FileHubMethod.RecordEnvelopeUpdate.toString());
     };
    }
  },[connected, SignalRHub, loading])

  const successUploadHandler = (file: SharepointFile): void => {
    dispatch(successUploadedDocument(file));
    // setDocuments( prevDocs => ({
    //   ...prevDocs,
    //   grouped:prevDocs.grouped,
    //   ungrouped:[ {...file, envelopeId:null} as UngroupedSharepointFile, ...prevDocs.ungrouped]
    // }));
  };

  const deleteHandler = (id: string): void => {
    dispatch(deleteDocument(id));
    // const docs = documents.ungrouped.filter(x => x.documentId !== id);
    // setDocuments( prevDocs => ({
    //   ...prevDocs,
    //   grouped:prevDocs.grouped,
    //   ungrouped:docs
    // }));
  };

  return (
    <>
      <Header />
        <Container className={classes.container}>
          <Box className={classes.box} >
            <Link className={classes.link} to='/'>
              <ArrowBack />
            </Link>
            <Typography className={classes.back}>{Labels.Back}</Typography>
          </Box>
          <div className={classes.order_info}>
            {!order && <OrderSkeleton />}
            {order && <OrderInfo order={order} onUpload={() => setOpen(true)} />}
            <Documents
              loading={loading}
              documents={orderDocuments}
              onDelete={deleteHandler}
              onReplace={replacementHandler}
              linksList={envelopes}
              orderFolder={order?.folder}
            />
          </div>
        </Container>
        <UploadDialog
          open={open}
          onClose={setOpen}
          onUploadSuccess={successUploadHandler}
          orderId={match.params.id}
          orderEntityName={entity_name}
        />
      <Footer />
    </>
  );
};

interface UploadDialogProps {
  open: boolean;
  onClose(flag: boolean): void;
  onUploadSuccess(file: SharepointFile): void;
  orderId:string;
  orderEntityName:string;
};

const UploadDialog: FunctionComponent<UploadDialogProps> = (props: UploadDialogProps): JSX.Element => {
  const {orderId , orderEntityName} = props;
  const [files, setFiles] = useState<File[]>([]);
  const [uploadingLaunched, setUploaded] = useState<boolean>(false);
  const _eventName = 'file-uploading-event';

  const changeHandler = (fileList: FileList) => {
    const length = fileList.length;
    const result = [];
    for (let i = 0; i < length; i++) {
      result.push(fileList[i]);
    }
    result.push(...files);
    setFiles(result);
  };

  const handleClose = () => {
    setFiles([]);
    props.onClose(false);
  };

  const deleteFileHandler = (fileName:string)=>{
    setFiles([...files.filter( file => file.name !== fileName)]);
  }

  const uploadFilesHandler = async (): Promise<void> => {
    const uploadFileEvent = new Event(_eventName);
    document.dispatchEvent(uploadFileEvent);
    setUploaded(true);
  };

  const toUploadFileModel = (file: Base64File): FileModel => {
    return {
      id:null,
      folders: [{recordId:orderId, entityName:orderEntityName}],
      document: {
        documentTypeId: 'F1E0BCC8-94AE-4D14-A3FA-0849D631674E',
        comment:'',
        isVisible: true,
        isCustomersPortalVisible: true,
        isEnvelopeCreated: false,
        isSigned: false,
        isSignedWithKabema: false,
      },
      file: {
        body: removeBase64Prefix(file.body),
        name: file.name,
      },
      forceUpload: false,
    }
  };

  return (
    <Modal onClose={handleClose} open={props.open}>
      <Container style={{ background: 'white', paddingTop: '20px' }}>
        <DndArea onFileListChange={changeHandler} />
        <DocumentList
          files={files}
          onDeleteFile={deleteFileHandler}
          eventName={_eventName}
          transformToUploadfileModel={toUploadFileModel}
          uploadSuccessHandler={props.onUploadSuccess}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap:10, paddingBottom: '20px' }}>
            <Button
              onClick={handleClose}
              color='primary'
              variant='outlined'
            >
              Schließen
            </Button>
            <Button
              onClick={uploadFilesHandler}
              color='primary'
              variant='outlined'
              disabled={uploadingLaunched}
              autoFocus
            >
              Hochladen
            </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default Order;
