import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';

import Routes from './routes';
import { Provider } from 'react-redux';
import store from './store';
import {SignalRState} from './reducers/signalR'
import { loginUserSuccess , setDisconnected } from './actions';
import { CssBaseline } from '@material-ui/core';
import './index.scss';
import axios from 'axios';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      const hubConnection = (store.getState().signal_R as SignalRState).SignalRHub
      const unsubscribe = async (): Promise<void> => {
        if (hubConnection) {
            await hubConnection.stop();
        }
        store.dispatch(setDisconnected());
      };
      await unsubscribe();
      sessionStorage.removeItem('token');
      window.location.replace('/');
    }
    return Promise.reject(error);
  }
);
let token = sessionStorage.getItem('token');
if (token !== null) {
  store.dispatch(loginUserSuccess(token));
}


const theme = createTheme({
  palette: {
    primary: {
       main: '#000'
     }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
