import React, { ReactElement, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Loader from '../../loader';
import { IndicatorStatus } from './document';

interface IndicatorProps {
  status: IndicatorStatus;
};

const useStyles = makeStyles({
  loading_icon:{
    height:22,
    width:22
  }
});

const Indicator: FunctionComponent<IndicatorProps> = (props): ReactElement | null => {
  const { status } = props;
  const classes = useStyles();

  if (status === 'submited') {
    return <CheckCircleOutlinedIcon htmlColor='green' />;
  }

  if (status === 'loading') {
    return <Loader className={classes.loading_icon} />;
  }

  if (status === 'error') {
    return <ErrorOutlineOutlinedIcon htmlColor='#d10019' />;
  }

  return null;
};

export default Indicator;