class FileHubMethod {
    private readonly _value: string;

    private constructor(value: string) {
      this._value = value;
    }

    public static readonly RecordEnvelopeUpdateRoot = new FileHubMethod('RecordEnvelopeUpdateRoot');
    public static readonly DSEEnvelopeUpdate = new FileHubMethod('DSEEnvelopeUpdate');
    public static readonly RecordEnvelopeCreate = new FileHubMethod('RecordEnvelopeCreate');
    public static readonly RecordEnvelopeUpdate = new FileHubMethod('RecordEnvelopeUpdate');

    public toString(): string {
      return this._value;
    }
}

export default FileHubMethod;