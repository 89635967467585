import React, { FunctionComponent, ReactElement } from 'react';
export interface LoaderProps {
  className?: string;
};

const Loader: FunctionComponent<LoaderProps> = (props: LoaderProps): ReactElement => {
  return (
    <svg
      className={props.className}
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      width='44px'
      height='50px'
      viewBox='0 0 44 50'
    >
      <rect x='0' y='13' width='6' height='25' fill='#333'>
        <animate
          attributeName='height'
          attributeType='XML'
          values='11;27;11'
          begin='0s'
          dur='0.6s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='y'
          attributeType='XML'
          values='19; 11; 19'
          begin='0s'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </rect>
      <rect x='18' y='13' width='6' height='25' fill='#333'>
        <animate
          attributeName='height'
          attributeType='XML'
          values='11;27;11'
          begin='0.15s'
          dur='0.6s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='y'
          attributeType='XML'
          values='19; 11; 19'
          begin='0.15s'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </rect>
      <rect x='36' y='13' width='6' height='25' fill='#333'>
        <animate
          attributeName='height'
          attributeType='XML'
          values='11;27;11'
          begin='0.3s'
          dur='0.6s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='y'
          attributeType='XML'
          values='19; 11; 19'
          begin='0.3s'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </rect>
    </svg>
  );
};

export default Loader;
